import * as React from "react";
import ProjectSection from "../components/ProjectSection";
import { Link } from "gatsby";
import LinkArrowSvg from "../images/link-arrow";
import ClientsSection from "../components/home/ClientSection";
import Banner from "../components/Banner";
// import KnowMoreSection from "../components/KnowMoreSection";
import Faq from "../components/Faq";
import { Seo } from "../components/seo";
import LetsConnect from "../components/LetsConnect";
import KnowAbout from "../components/KnowAbout";
import WhatWeAre from "../components/WhatWeAre";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
const Questions = [
  "Which industries does your website design agency in India serve?",
  "How do you approach a UI UX design project?",
  "What makes Octet stand out as the best UI UX design agency?",
  "What is the standard timeline for a UI UX design project?",
  "Can your UI UX design company in India create web and mobile apps?",
];

const SuratPage = () => {
  const banner = {
    title: `UI UX Design Agency in India`,
    content:
      "We are among the best <strong class='font-semibold'> <a class='link-text-para' href='/' title='UI UX Design company in India'>UI UX Design company in India</a></strong>. Our expertise lies in generating visually appealing and simple-to-use digital products that your clients will adore.",
    img: ["why-octet-india.webp"],
    imageAlt: "Octet Design Studio- UI UX Design Agency in Surat",
    imageTitle: "UI UX Design Company, Surat",
  };
  const projectData = [
    {
        title: "Survey2Connect",
        desc: "A Research Tech Platform",
        Industry: "Enterprise",
        firstAlt: "UI UX Design To Create Survey",
        firstTitle: "Survey Creation Screen",
        secondAlt: "Ui Design To Create Questionnaire For Survey",
        secondTitle: "Questionnaire For Survey",
        WhatWeDid: [
          {
            title: "User Research",
            link: "/user-research/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
          {
            title: "UI Development",
            link: "/ui-development/",
          },
        ],
        link: "/project/survey2connect/",
        images: ["survey.webp", "survey-2.webp"],
    },
    {
        title: "Unilogix App",
        desc: "A Logistics Insights Application",
        Industry: "Logistics",
        firstAlt: "Mobile UI UX Screen Of Logistics Insight Application",
        firstTitle: "Logistics Services List",
        secondAlt: "Order And Warehouse Details",
        secondTitle: "Order Details",
        WhatWeDid: [
          {
            title: "Branding",
            link: "/branding/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
          {
            title: "UI Development",
            link: "/ui-development/",
          },
        ],
        link: "/project/logistics-insight-app-design/",
        images: ["uni-logics.webp", "uni-logics-2.webp"],
    },
  ];
  const faqData = [
    {
      para: [
        `Our web design company in India has experience in various industries, including healthcare, finance, e-commerce, education, and more. Our versatile team can adapt to your industry needs to deliver tailored design solutions.`,
      ],
    },
    {
      para: [
        `Our website design company collaborates closely with clients to understand their goals, target audience, and project requirements. We ensure a user-centric design that aligns with their objectives. The approach of our UI UX design service in India involves thorough research, user analysis, wireframing, prototyping, visual design, and rigorous testing. `,
      ],
    },
    {
      para: [
        `Our team of exceptional designers, proactive approach, and dedication to innovation make our agency stand out. Our commitment to user-centered design concepts and in-depth market knowledge make us a reliable design partner.`,
      ],
    },
    {
      para: [
        `The timeline for a UI UX design project varies depending on its complexity and scope. We collaborate closely with our clients to develop project milestones and schedules that meet their requirements. Smaller projects often take a few weeks, whereas larger projects take a few months.`,
      ],
    },
    {
      para: [
        `Yes, we are known for being the best web and <strong class='font-semibold'>mobile app design company in India</strong>. Our UI UX designers are well-versed in responsive design principles, so your digital products will perform effortlessly across various devices and screen sizes.`,
      ],
    },
  ];
  const lastLength = projectData;
  const info = {
    title: "Create Excellence with <span class='h1-span'>the Best UI UX Design Services</span>",
    link: "/contact-us/",
    text: "Contact Us",
  };
  const whatWeAreData = {
    title: "Boost Business with <span class='h1-span'>Website Design Agency in India</span>",
    des: "We are a <strong class='font-semibold'>website design company In India</strong> that discovers concepts using scalable, environmentally friendly, and forward-thinking design methods.",
    items: [
      {
        title: "User Research",
        content:
          "Our research-driven approach enables us to create tailored interfaces that align with your business objectives. As India's best UI UX agency, we enhance online visibility and user experience.",
        link: "/user-research/",
        linkText:
          'Our <a href="/user-research/" title="User Research Agency">user research agency</a> develops strategies to enhance digital visibility and user experience.',
      },
      {
        title: "UI UX Designing",
        content:
          "Octet is the <strong class='font-semibold'>best web design company in India</strong>, offering streamlined solutions to bring your vision to life. We fuse originality and functionality to design attractive interfaces for engaging user experiences.",
        link: "/ui-ux-designing/",
        linkText:
          'Our <a href="/ui-ux-designing/" title="UI UX design agency">UI UX Design Agency</a>, has a plethora of experience providing customized solutions for various industries.',
      },
      {
        title: "Usability Testing",
        content:
          "We use advanced usability metrics to identify user pain points accurately and fine-tune the interface elements for more promising engagement. Our website design company in India employs advanced techniques to optimize your user experiences.",
        link: "/usability-testing/",
        linkText:
          'Our <a href="/usability-testing/" title="Usability Testing Agency">usability testing company</a> uses improved procedures to ensure your user experiences are well-optimized.',
      },
      {
        title: "UI Development",
        content:
          "We craft dynamic, engaging platforms that captivate users and increase their sessions. As the best mobile app design company in India, we craft custom user interfaces using design principles, programming languages, and core industry expertise.",
        link: "/ui-development/",
        linkText:
          'Our <a href="/ui-development/" title="UI Development Company">UI Development Company</a> creates engaging experiences by putting user-centric design upfront.',
      },
    ],
    mainLink: {
      link: "/services/",
      text: "View All UI UX Design Services",
    },
  };
  const faqDes = `We've compiled frequently asked questions to provide quick and informative answers about our UI UX design company in India, UI UX design services, creation process, and pricing. If you still have any questions, don’t hesitate to <a href="/contact-us/" title="Contact Us"> contact us</a>.`;
  const knowAboutDes = [
    "<strong class='font-semibold'><a href='/' title='Octet design studio' class='link-text-para'>Octet</a></strong> is the <strong class='font-semibold'>best UI UX design agency in India</strong>, with over years of experience transforming SaaS, B2B, and enterprise-grade products. Our proactive approach simplifies complex applications, delivering intuitive, user-centric solutions across diverse industries. Being the leading UX UI design services provider, we operate with an agile process that empowers businesses to unlock their potential and create functional, visually appealing products.",
  ];
  const knowAboutTitle = "Work with the Best UI UX Design Agency In India";
  const commonContent = {
    title: "Exceptional Results with our Web Design Company",
    para: `Octet is one of India's best UI UX agency, with notable experience and impressive customer references across sectors and geographies.`,
    content: [
      {
        title: "Cross-Platform Adaptability",
        text: "We prioritize responsive designs that work seamlessly across all devices and screen sizes, enhancing user accessibility. We are your go-to web design agency in India, aiming to deliver exceptional UI UX design services tailored to your needs.",
      },
      {
        title: "User-Centric Approach",
        text: "We adopt a user-centric approach to product development and prioritize their needs and preferences. Our <strong class='font-semibold'>UI UX design services in India</strong> customize products to meet the target market's expectations and ensure customer satisfaction.",
      },
      {
        title: "Continuous Improvement",
        text: "Our design methodology is based on a continuous improvement process informed by extensive user feedback & market data. Our website design agency in India ensures that our designs align with user behavior & remain responsive.",
      },
      {
        title: "Increased Conversion Rates",
        text: "The approach of our website design agency in India is grounded in data-driven insights and proven strategies. This results in a website design that effectively engages and converts its audience.",
      },
    ],
  };
  const commonContent1 = {
    title: "Elevate Solutions with <span class='h1-span'>UI UX Design Agency in India</span>",
    para: ['Our research-driven design process has transformed businesses by creating customer-centric solutions that allow them to serve millions of people.'],
    content: [
      {
        title: "1. Briefing",
        text: "To establish the nature of our collaboration, our UI UX company in India carries out design audits, competitive analyses, discussions, and usability testing that clarify product and business difficulties.",
      },
      {
        title: "2. Defining",
        text: "We determine your target market by interacting with your customers and utilizing various techniques to grasp their issues fully. As a team, we define the project, set deadlines, write down objectives, and decide on metrics to help you succeed.",
      },
      {
        title: "3. Strategizing",
        text: "Our UI UX design company in India strategizes the user experience flow, develops guidelines, and structures information architecture to organize your website's content and effectively achieve its purpose.",
      },
      {
        title: "4. Sketching",
        text: "As a UI UX design company, we then formulate an effective visual hierarchy for your app or website to facilitate comprehension. We aim to provide you with the best UI UX design services in India that enhance the user experience and maximize your product's potential.",
      },
      {
        title: "5. Designing & Testing",
        text: "We develop a prototype and test the end product's functionality after finalizing the layout and designs. This enables our UI UX design company to evaluate the final product, ensuring it meets the required standards and specifications.",
      },
    ],
  };
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "Which industries does your website design agency in India serve?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our web design company in India has experience in various industries, including healthcare, finance, e-commerce, education, and more. Our versatile team can adapt to your industry needs to deliver tailored design solutions."
                }
              },{
                "@type": "Question",
                "name": "How do you approach a UI UX design project?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our website design company collaborates closely with clients to understand their goals, target audience, and project requirements. We ensure a user-centric design that aligns with their objectives. The approach of our UI UX design service in India involves thorough research, user analysis, wireframing, prototyping, visual design, and rigorous testing."
                }
              },{
                "@type": "Question",
                "name": "What makes Octet stand out as the best UI UX design agency?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our team of exceptional designers, proactive approach, and dedication to innovation make our agency stand out. Our commitment to user-centered design concepts and in-depth market knowledge make us a reliable design partner."
                }
              },{
                "@type": "Question",
                "name": "What is the standard timeline for a UI UX design project?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The timeline for a UI UX design project varies depending on its complexity and scope. We collaborate closely with our clients to develop project milestones and schedules that meet their requirements. Smaller projects often take a few weeks, whereas larger projects take a few months."
                }
              },{
                "@type": "Question",
                "name": "Can your UI UX design company in India create web and mobile apps?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we are known for being the best web and mobile app design company in India. Our UI UX designers are well-versed in responsive design principles, so your digital products will perform effortlessly across various devices and screen sizes."
                }
              }]
            }
          `}
        </script>
      </Helmet>
      <Layout>
        <Banner
          content={banner}
          // short={true}
          page={"ahmedabad-home"}
          noLocation
        />
        <WhatWeAre data={whatWeAreData} />
        <KnowAbout
          des={knowAboutDes}
          title={knowAboutTitle}
          imageAlt={"Logo of UI UX Design Agency in India"}
          imageTitle={"UI UX Design Agency in India's Logo"}
        />
        <CommonServiceComponent data={commonContent} mode={"grey"} nopara />
        <CommonServiceComponent
          data={commonContent1}
          mode={"light"}
          list="single"
        />
        <section className="project-section lg:py-[200px] md:pb-[125px] md:pt-[140px] pt-[100px] pb-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
          <div className="container">
            <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
              Successful Projects with <span className="h1-span">our UI UX Design Services</span>
            </h2>
          </div>
          {projectData.map((data, index) => (
            <div key={index}>
              <ProjectSection num={index} lastLength={lastLength} data={data} />
            </div>
          ))}
          <div className="container lg:mt-[-100px] md:mt-[-35px] mt-[-40px]">
            <Link
              title="UI UX Design Portfolio"
              to="/projects/"
              className="animated-link font-medium font-heading text-[20px] md:text-[22px] lg:text-[30px] md:leading-[1.33] leading-[normal] tracking-[.05em] flex items-center gap-[32px] md:gap-[38px]"
            >
              <p className="max-w-[262px] md:max-w-none w-auto font-heading">
                See more work by our UI UX design agency{" "}
              </p>
              <span>
                <LinkArrowSvg />
              </span>
            </Link>
          </div>
        </section>
        <ClientsSection title={'Trusted by Clients Worldwide'} />
        <LetsConnect data={info} />
        <Faq section Questions={Questions} faqData={faqData} des={faqDes} />
        {/* <KnowMoreSection nin="Contact Us" Include="services" /> */}
      </Layout>
    </>
  );
};

export default SuratPage;

export const Head = () => (
  <Seo
    title="UI UX Design Agency in India | Website Design Company | Octet"
    description="Octet is a premier UI UX Design Agency in India, providing exceptional services as a Website Design Company. We specialize in creating innovative, user-focused digital experiences."
  />
);
